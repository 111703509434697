
import { defineComponent, onMounted, ref, reactive, nextTick } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import PaginationUi from "@/components/ABilling/PaginationXS.vue";
import {
  addInsuranceCompany,
  getInsuranceCompany,
  searchInsuranceCompanys,
  updateInsuranceCompany,
} from "@/api/code-master-insurance-company.api";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import useFilters from "@/modules/common/useFilters";
import { getRehab } from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import debounce from "lodash.debounce";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}

export interface Data {
  items: [];
  displayNotInUse : boolean;
  displayInactive: boolean;
  selectedItem: any;
  isEditMode: boolean;
  orderBy: string;
  IsAdvancedOrderBy: boolean;
  isDecr: boolean;
  keyword: string;
  pagination: Pagination;
  dict: {
    payerTypes: { code: string; description: string; }[];
    networkTypes: string[];
  };
}
export default defineComponent({
  name: "InsuranceCompany",
  components: { PaginationUi },
  setup() {
    let organizationId = ref<string | null>("");
    const router = useRouter();
    const { orderList } = useFilters();
    orderList.value = [
      {
        name: " ",
        key: " ",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Insurance Companies",
        key: "name",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Payer ID",
        key: "payerId",
        isFilter: true,
        keyword: null,
      },
      {
        name: "In Use",
        key: "inUse",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Active",
        key: "isActive",
        isFilter: true,
        keyword: null,
      },
    ];

    let data = reactive<Data>({
      items: [],
      displayNotInUse: false,
      displayInactive: false,
      selectedItem: undefined,
      isEditMode: true,
      keyword: "",
      orderBy: "name",
      IsAdvancedOrderBy: false,
      isDecr: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      dict: {
        payerTypes: [],
        networkTypes: ["In Network", "Out Of Network"]
      }
    });

    const isPhone = helpers.regex(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im); // eslint-disable-line

    const InsuranceCompanyRules = {
      selectedItem: {
        name: { required:helpers.withMessage("Required", required) },
        payerId: { required:helpers.withMessage("Required", required) },
        officeNumber: {
          isPhone: helpers.withMessage("Phone number is invalid", isPhone)
        },
        otherNumber: {
          isPhone: helpers.withMessage("Phone number is invalid", isPhone)
        },
        fax: {
          isPhone: helpers.withMessage("Fax number is invalid", isPhone)
        }
      },
    };

    let v$ = useVuelidate(InsuranceCompanyRules, data as never);

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.selectedItem = {
        payerType: null,
        network: null,
        state: null,
        country: "1",
      };

      await getAll();
    });

    async function debounceGetAll() {
        await debounce(getAll, useFilters().debounceMs)();
    }

    async function getAll() {
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }
      let request = {
        keyword: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: [order],
        displayInactive: data.displayInactive,
        displayNotInUse: data.displayNotInUse,
        rehabId: getRehab(),
      };

      const res = await searchInsuranceCompanys(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      v$.value.$reset();
    }

    async function selectItem(item) {
      //TODO DEBT
      data.selectedItem = { country: item.country };
      await nextTick();

      data.selectedItem = item;
      data.isEditMode = false;
      router.push({ path: "/settings/insuranceCompanyAddPage/" + item.id });
    }

    async function addItem() {
      const result = await v$.value.selectedItem.$validate();
      if (result) {
        data.selectedItem.rehabId = getRehab();
        data.selectedItem.id = await addInsuranceCompany(data.selectedItem);
        data.isEditMode = false;
        await getAll();
      }
    }

    async function updateItem() {
      const result = await v$.value.selectedItem.$validate();
      if (result) {
        data.selectedItem.rehabId = getRehab();
        await updateInsuranceCompany(data.selectedItem);
        data.isEditMode = false;
        //await getAll();
      }
    }

    async function addMode() {
      router.push({ name: "insuranceCompanyAddNewPage" });
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function selectFilter(header) {
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;

      await getAll();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    function edit() {
      data.isEditMode = true;
    }

    async function clear() {
      if (data.selectedItem.id) {
        const oldItem = await getInsuranceCompany(data.selectedItem.id);
        data.selectedItem.code = oldItem.code;
        data.selectedItem.description = oldItem.description;
        data.selectedItem.rate = oldItem.rate;
        data.selectedItem.category = oldItem.category;
        data.selectedItem.isActive = oldItem.isActive;
        data.isEditMode = false;
      } else {
        data.selectedItem = { state: null, country: "1" };
      }
    }





    return {
      pageChanged,
      pageSizeChanged,
      organizationId,
      selectItem,
      updateItem,
      addItem,
      addMode,
      selectFilter,
      getSortInfo,
      orderList,
      edit,
      debounceGetAll,
      getAll,
      clear,
      v$,
      data,
    };
  },
});
